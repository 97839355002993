import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-exec-base-coin',
  templateUrl: './edit-exec-base-coin.component.html',
  styleUrls: ['./edit-exec-base-coin.component.css']
})
export class EditExecBaseCoinComponent implements OnInit {

  selectedItems = [];
  dropdownList = [
  ];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'coinShortName',
    textField: 'coinShortName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: false,
    enableCheckAll: false,
  };

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  coinPairId: any;
  productForm: FormGroup;

  aipPerList: any = [];
  flag: boolean = false
  max = 80
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService, private fb: FormBuilder) {

    this.productForm = this.fb.group({
      name: '',
      quantities: this.fb.array([]),
    });
    this.activatedRoute.queryParams.subscribe((res) => {
      this.coinPairId = res.id
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinList()
    this.getPairData();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'baseCoin': new FormControl('', [Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'swapCharge': new FormControl('', [Validators.required]),
      'swapMinAmount': new FormControl('', [Validators.required]),
      'swapMaxAmount': new FormControl('', [Validators.required]),
      'baseCoinSelect': new FormControl(''),
      'execCoin': new FormControl('')
    })
  }
  set_max(me: number) {
    me = Number(me)
    if (me > this.max) {
      setTimeout(() => {
        this.addSwatchesForm.patchValue({
          percent: this.max
        })
      }, 100);
    }
  }
  coinDataArray: any = []
  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinDataArray = res.data
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  getPairData() {
    let url = `wallet/admin/view-swapCoinPair-by-Id?CoinPairId=${this.coinPairId}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();
        this.addSwatchesForm.patchValue({
          swapCharge: res.data.swapPairfee,
          swapMinAmount: res.data.swapPairMinAmount,
          swapMaxAmount: res.data.swapPairMaxAmount,
          baseCoinSelect: res.data.baseCoin,
          execCoin: res.data.exCoin
        })
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  editSwapPairFee() {
    const data = {
      swapPairMaxAmount: this.addSwatchesForm.value.swapMaxAmount,
      swapPairMinAmount: this.addSwatchesForm.value.swapMinAmount,
      swapPairfee: this.addSwatchesForm.value.swapCharge
    }
    let url = `wallet/admin/edit-exCoin-pair-fee?coinPairId=${this.coinPairId}`

    this.commonService.postApi(url, data).subscribe((res) => {
      if (res['status'] == '200') {
        this.commonService.hideSpinner();
        this.router.navigate(['/list-swap-coin'])
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  getResponse(event) {
    let selectedCoinName = event.target.value
    this.dropdownList = this.coinDataArray.filter(x => x.coinShortName != selectedCoinName)

  }

  onItemSelect(item: any) {
  }


  removeCoin(i, coinShortName) {
    this.dropdownList.splice(i, 1)
    coinShortName['isSelected'] = false

  }
  totalPercentage: number = 0

  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      qty: '',
      price: '',
    })
  }

  addQuantity() {
    this.quantities().push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }



}
