import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {
  addFaqForm:FormGroup;
  pageNumber:number=1
  topickey: any;
  id:any
  constructor(public route:Router,public service:ServiceService, public active:ActivatedRoute) 
  {
    this.active.queryParams.subscribe((params)=>{

      this.topickey=params.topickey
  this.id = params.faqId
      
    })
   }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation(){
    this.addFaqForm= new FormGroup({
      'title':new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'faqCategory' :new FormControl('')
    })
  }
  
  editData:any
  editFaq(){
    this.service.get("/static/admin/static-content/get-faq-question-answer-by-Id?faqId="+this.id).subscribe((res:any)=>{
      if (res.status=200) {
        this.editData=res.data;
       this.addFaqForm.patchValue({
        'title':this.editData.question,
        'description':this.editData.answer,
        'faqCategory' : this.editData.topicKey
      })
      }
     },err=>{
    
       this.service.hideSpinner();
       if(err['status']=='401'){
         this.service.onLogout();
         this.service.toasterErr('Unauthorized Access');
       }else{
       this.service.toasterErr('Something Went Wrong');
    }
     })
 
   } 

faqDataCatgory:any = []
totalRecords:any
itemsPerPage:any = 100
  faqCategoryList(){
    let url =`static/admin/static-content/get-FAQ-category-list`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        this.faqDataCatgory=res.data;
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
 
  
  addFaq(){
    let request = {
      'answer':this.addFaqForm.value.description,
      'question':this.addFaqForm.value.title,
      'faqId' : this.id,
      'topicKey' : this.topickey 
    }
   this.service.post(`static/admin/static-content/update-faq-subcategory-by-id`,request).subscribe((res:any)=>{
     if (res.status=200) {
       this.service.toasterSucc(res.message)
       this.route.navigate(['/faq-management'])
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })

  }

}
