import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-fee-manage-new',
  templateUrl: './fee-manage-new.component.html',
  styleUrls: ['./fee-manage-new.component.css']
})
export class FeeManageNewComponent implements OnInit {
  pageNumber: number = 1;
  privilegePrice: any;
  twoDate: any;
  currencycoin: any = "BTC";
  allcoin = "BTC";
  Hggcoin: any;
  show: boolean = false;
  time: any;
  profitobj: any = {};
  walletCheckFee: any = [];
  type = "WITHDRAW";
  optionFee: any = "WITHDRAW";
  currTab: any;
  curlTab: any = 'dopositFeeWithdrawFee'
  numRegxForDot = /^\d{0,6}(\.\d{1,6})?$/;
  usdExceedAmount:any
  btcdata: any = {};
  calender: any = { todate: "", formdate: "" };
  minAge: Date;
  totalExceedAmount:any
  feecoinArry: any = [];
  mininArry: any = [];
  btcFeeVali: any;
  fromDate: any;
  coinlist: any = [];
  coinListArr: any = [];
  loopdata: any = [];
  manageCoins: boolean = true;
  manageTrading: boolean = true;
  particularData: any = [];
  editedCoinName: any;
  manageCoinForm: FormGroup;
  manageCoinPair: FormGroup;
  particularDatad: any = [];
  coinpairlistarray: any = [];
  baseCoinn: any;
  executableCoinn: any;
  visiblen: boolean;
  tradingDetail: any = []
  coinList: any = [];
  coinListBuffer: any = []
  showDetail: any = "CRYPTO";
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.manageCoinForm = new FormGroup({
      isVisible: new FormControl(""),
      remark: new FormControl(""),
      isWithdrawl: new FormControl(""),
      isDeposite: new FormControl(""),
    });
    this.manageCoinPair = new FormGroup({
      pairedCoin: new FormControl(""),
      visible: new FormControl(""),
      baseCoin: new FormControl(""),
    });
    this.defaults();
    this.getCoinWalletList();
    this.getcoinPairlist();
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate()
    );
    this.getCoinLists();
    this.getCoinNetworkLists()
  }

  getcoinPairlist() {
    this.service
      .post("wallet/coin/get-coinPair-list", "")
      .subscribe((res: any) => {
        this.coinpairlistarray = res.data;
      });
  }

  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get("wallet/coin/get-coin-list-isVisible").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.coinlist = res.data;

          this.coinlist.forEach((element) => {
            this.coinListArr.push(element.coinShortName);
          });
          let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC"];
          this.coinlist = this.coinlist.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
          this.getCoinStatus();
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  updateMnageCoins() {
    this.service.showSpinner();
    let data = {
      coinName: this.editedCoinName,
      confermation: 0,
      isDeposite: this.manageCoinForm.value.isDeposite,
      isVisible: this.manageCoinForm.value.isVisible,
      isWithdrawl: this.manageCoinForm.value.isWithdrawl,
      remark: this.manageCoinForm.value.remark,
    };
    this.service
      .post("wallet/admin/set-coin-status?coin=" + this.editedCoinName, data)
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.particularData = res.data;

            this.service.hideSpinner();
            this.service.toasterSucc(res.message);
          }

          else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }
  updateCoinvisible() {
    this.service.showSpinner()
    let url = `wallet/coin/set-Coin-Visible?coinName=${this.editedCoinName}&isVisible=${this.manageCoinForm.value.isVisible}&iswithdraw=${this.manageCoinForm.value.isWithdrawl}&remark=${this.manageCoinForm.value.remark}&isDeposit=${this.manageCoinForm.value.isDeposite}`
    this.service
      .post(url, {})
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.particularData = res.data;
            this.service.hideSpinner();
            this.service.toasterSucc(res.message);
          }

          else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  updateCoinPairs() {
    let data = {
      baseCoin: this.baseCoinn,
      executableCoin: this.executableCoinn,
      visible: this.manageCoinPair.value.visible,
    };
    this.service.post("wallet/coin/Set-coinPair-visibility", data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          this.service.toasterSucc(res.message);

          this.router.navigate(["/fee-management"]);
        } else {
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  editStaff(element) {
    this.editedCoinName = element;
    this.manageCoins = false;
    let data = {
      coinName: "string",
      confermation: 0,
      isDeposite: true,
      isVisible: true,
      isWithdrawl: true,
      remark: "string",
    };
    this.service.get("wallet/admin/get-coin-status?coin=" + element).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.particularData = res.data;
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  manageCoinsss() {
    this.manageTrading = true;
    this.getCoinWalletList();
    this.getcoinPairlist();
  }

  editStafff(baseCoin, executableCoin, visible) {
    this.manageTrading = false;
    this.baseCoinn = baseCoin;
    this.executableCoinn = executableCoin;
    this.visiblen = visible;
    let data = {
      baseCoin: this.manageCoinPair.value.baseCoin,
      executableCoin: this.manageCoinPair.value.executableCoin,
      visible: this.manageCoinPair.value.visible,
    };
  }

  getCoinStatus() {
    this.service.showSpinner();
    this.coinListArr.forEach((element) => {
      this.service
        .get("wallet/admin/get-coin-status?coin=" + element)
        .subscribe(
          (res: any) => {
            if (res.status == 200) {
              this.loopdata.push(res.data);
              this.service.hideSpinner();
            }
            let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC"];
            this.coinlist = this.coinlist.filter((ele) => {
              return !removeCoin.includes(ele.coinShortName);
            });
          },
          (error) => {
            this.service.hideSpinner();
          }
        );
    });
  }

  manageCoinss() {
    this.manageCoins = true;
  }

  defaults() {
    this.getCoinList();
  }
  todate() {
    this.twoDate = new Date(this.calender.todate);
    this.twoDate = this.twoDate.getTime();
  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate);
    this.fromDate = this.fromDate.getTime();
  }
  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == "privilege") {
    } else if (this.currTab == "Tacker") {
    } else if (this.currTab == "income") {
    }
    this.getCoinList();
  }

  selectTabs(tabs) {
    this.currTab = ''
    this.curlTab = tabs
    if (this.curlTab == 'tradingFeedata') {
      this.selectTab('Tacker')
      this.getCoinList();

    }
  }


  coinfunction(coin) {
    this.currencycoin = coin;
  }

  demo(val) {
    this.optionFee = val;
    this.show = false;
  }
  getCoinList() {
    this.service.showSpinner();
    this.service.get("wallet/coin/get-coin-list-isVisible").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.feecoinArry = res["data"];
          this.minimumFeesArray = res["data"];

          this.Hggcoin = res["data"][5].privilegePrice;

          this.feecoinArry.forEach((obj) => {
            let pushobj = {
              coinShortName: obj.coinShortName,
              withdrawlfee: obj.withdrawlFee,
            };
            let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC"];
            this.coinlist = this.coinlist.filter((ele) => {
              return !removeCoin.includes(ele.coinShortName);
            });
          });
          this.minimumFeesArray.forEach((obj) => {
            let pushobj = {
              coinShortName: obj.coinShortName,
              withdrawalAmount: obj.withdrawalAmount,
              withdrawalAmountMax: obj.withdrawalAmountMax
            };
          });
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  minimumFeesArray: any = [];
  updateminimumfeeapi(coinShortName, withdrawalAmount, withdrawalAmountMax) {
    if (!this.numRegxForDot.test(withdrawalAmount)) {
      this.service.toasterErr("Enter valid input.");
    } else if (withdrawalAmount > 100) {
      this.service.toasterErr("Coin fee can't be greater than 100%");

      return;
    }
    else if (!this.numRegxForDot.test(withdrawalAmountMax)) {
      this.service.toasterErr("Enter valid input.");
    }
    else if (withdrawalAmountMax > 100) {
      this.service.toasterErr("Coin fee can't be greater than 100%");

      return;
    }
    else {
      let url = `wallet/admin/fee-management/set-minimum-withdrawal-amount?coinName=${coinShortName}&withdrawalAmount=${withdrawalAmount}&withdrawalAmountMax=${withdrawalAmountMax}`
      this.service.showSpinner();
      this.service.get(url)
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.minimumFeesArray = res["data"];
              this.service.toasterSucc('Amount Set Successfully');
              this.getCoinList();
            } else {
              this.service.toasterErr("rghfg");
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
    }
  }

  updatefeeapi(coinShortName, withdraw) {
    if (withdraw == "") {
      this.service.toasterErr("Enter valid input.");
      return;
    }

    if (!this.numRegxForDot.test(withdraw)) {
      this.service.toasterErr("Enter valid input.");
    } else if (withdraw > 100) {
      this.service.toasterErr("Coin fee can't be greater than 100%");

      return;
    } else {
      this.service.showSpinner();
      this.service
        .get(
          "wallet/admin/fee-management/set-withdrawal-fee?coinName=" +
          coinShortName +
          "&withdrawalFee=" +
          withdraw
        )
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.feecoinArry = res["data"];
              this.service.toasterSucc(res["message"]);
              this.getCoinList();
            } else {
              this.service.toasterErr("rghfg");
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
    }
  }
  updateTacker(coin, tacker, macker) {
    if (tacker == "" || macker == "") {
      this.service.toasterErr("Enter valid input.");
      return;
    }
    if (!this.numRegxForDot.test(tacker)) {
      this.service.toasterErr("Enter valid input.");
    } else if (!this.numRegxForDot.test(macker)) {
      this.service.toasterErr("Enter valid input.");
      return;
    } else {
      let data = {
        coinName: coin,
        makerFee: macker,
        takerFee: tacker,
      };
      this.service.showSpinner();
      this.service
        .post("wallet/admin/fee-management/set-taker-maker-fee", data)
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
    }
  }

  getCoinLists() {
    this.service.get('wallet/coin/get-coin-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.coinList = res['data']
        this.coinListBuffer = this.coinList;
        this.tradeDataDetails(this.showDetail)
        if(this.coinList){
          this.totalExceedAmount = this.coinList[0].usdExceedAmount
        }
      }
    })
  }
  coinNetworkArray: any = []
  getCoinNetworkLists() {
    this.service.get('wallet/coin/get-network-list').subscribe((res: any) => {
      if (res['status'] == 200) {
        this.coinNetworkArray = []
        res.data.CoinList.forEach((element: any) => {
          let network = []
          element.forEach((obj: any, index: number) => {
            network.push({
              networkName: obj.networkName,
              withdrawlFee: obj.withdrawlFee,
              minimumWithdrawal: obj.minimumWithdrawal,
              withdrawalAmountMax: obj.withdrawalAmountMax
            })
            let temp = element[0]
            temp['network'] = network
            if (index == element.length - 1) {
              this.coinNetworkArray.push(temp)
            }
          });
        });
      }
    })
  }

  navigateTo() {
    this.router.navigate(['/fees'])
  }

  tradeDataDetails(showSection) {
    this.showDetail = showSection;
    if (this.showDetail == 'CRYPTO') {
      this.coinList = this.coinListBuffer.filter(x => x.coinType != 'CRYPTOWITHDRAWAL')
    } else {
      this.coinList = this.coinListBuffer.filter(x => x.coinType == 'CRYPTOWITHDRAWAL')
    }
    // if (this.showDetail == 'CRYPTO') {
    //   this.coinList = this.coinListBuffer.filter(x => x.coinType != 'fiat')
    // } else {
    //   this.coinList = this.coinListBuffer.filter(x => x.coinType == 'fiat')
    // }
  }
  selectedObject: any
  getValue:any = {}
  openModal(item) {
    this.selectedObject = item
    this.selectedNework = item.network[0]
    $('#editCryptoModal').modal({
      backdrop: "static"
    })
  }
  openModalSS(item){
    this.getValue = item
    $('#withdrwalLimitSetDaily').modal({
      backdrop:"static"
    })
  }
  openFiatModal(item) {
    this.selectedObject = item
    this.selectedNework = item.network[0]
    $('#editFiatModal').modal({
      backdrop: "static"
    })
  }
  selectedNework: any = {}
  updateNetwork() {


    let data = {
      "withdrawalAmountMax": this.selectedNework.withdrawalAmountMax,
      "minimumWithdrawal": this.selectedNework.minimumWithdrawal,
      "withdrawlFee": this.selectedNework.withdrawlFee
    };
    data = this.service.removeEmptyKey(data)
    this.service.showSpinner();
    this.service
      .post(`wallet/coin/update-fee-price?coinName=${this.selectedObject.coinShortName}&network=${this.selectedNework.networkName}`, data)
      .subscribe((res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.getCoinNetworkLists()
          $('#editCryptoModal').modal('hide')
          this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
        (err) => {
          this.service.hideSpinner();
        })
  }
  updateDailyLimitValue(){
    let url = `wallet/coin/set-coin-exceed-amount?coinAmount=${this.getValue.coinExceedAmount}&coinId=${this.getValue.coinId}`
    this.service.showSpinner();
    this.service.put(url,{}).subscribe((res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.getCoinNetworkLists()
          $('#withdrwalLimitSetDaily').modal('hide')
          this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
        (err) => {
          this.service.hideSpinner();
        })
  }
  updateFiat() {
    let data = {
      "depositFee": this.selectedNework.depositFee,
      "withdrawlFee": this.selectedNework.withdrawlFee
    };
    data = this.service.removeEmptyKey(data)
    this.service.showSpinner();
    this.service
      .post(`wallet/coin/update-fee-price?coinName=${this.selectedObject.coinShortName}`, data)
      .subscribe((res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.getCoinNetworkLists()
          $('#editCryptoModal').modal('hide')
          this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
        (err) => {
          this.service.hideSpinner();
        })
  }

  validateFormCold() {
    if (this.selectedNework.withdrawalAmountMax || this.selectedNework.minimumwithdraw || this.selectedNework.withdrawFee) {
      return false
    } else {
      return true
    }
  }

    updatePairMinAmount(base,exe,swapFee) {
      let url = `wallet/coin/update-trade-minimumValue?baseCoin=${base}&exCoin=${exe}&swapFee=${swapFee}`
      this.service.post(url, '').subscribe(res => {
        this.service.hideSpinner();
        this.service.toasterSucc(res['message'])
        this.getcoinPairlist()
      }, err => {
  
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.onLogout();
          this.service.toasterErr('Unauthorized Access');
        } else {
          this.service.toasterErr('Something Went Wrong');
        }
      })
      
    }
    openUsdSetModal(){
      this.totalExceedAmount = this.usdExceedAmount
      $('#usdAmountSetModal').modal({
        backdrop:"static"
      })
    }
    updateUSDLimitValue(){
      let url = `wallet/coin/set-coinUsd-exceed-amount?usdAmount=${this.usdExceedAmount}`
      this.service.showSpinner();
      this.service.put(url,{}).subscribe((res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $('#usdAmountSetModal').modal('hide')
            this.service.toasterSucc(res["message"]);
          } else {
            this.service.toasterErr(res["message"]);
            this.service.hideSpinner();
          }
        },
          (err) => {
            this.service.hideSpinner();
          })
    }
}
