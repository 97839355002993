import { EditAipPercentComponent } from './aip-management/edit-aip-percent/edit-aip-percent.component';
import { AddAipPercentComponent } from './aip-management/add-aip-percent/add-aip-percent.component';
import { ListAipPercentageComponent } from './aip-management/list-aip-percentage/list-aip-percentage.component';
import { EditBannerComponent } from './banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './banner-management/view-banner/view-banner.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { MarketManagementComponent } from './market-management/market-management.component';
import { SettingComponent } from './setting/setting.component';
import { SubAdminManagementComponent } from './sub-admin-management/sub-admin-management.component';
import { WalletManagementComponent } from './wallet-management/wallet-management.component';
import { StaticsContentComponent } from './statics-content/statics-content.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermAndServiceComponent } from './term-and-service/term-and-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ExchangeManagementComponent } from './exchange-management/exchange-management.component';
import { PaymentAndTransactionComponent } from './payment-and-transaction/payment-and-transaction.component';
import { KycManagementComponent } from './kyc-management/kyc-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { AddWalletAddressComponent } from './add-wallet-address/add-wallet-address.component';
import { WithdrawlFeeComponent } from './withdrawl-fee/withdrawl-fee.component';
import { DailyLimitComponent } from './daily-limit/daily-limit.component';
import { WithdrawlLimitComponent } from './withdrawl-limit/withdrawl-limit.component';
import { PrevilageSettingComponent } from './previlage-setting/previlage-setting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BannerSettingComponent } from './banner-setting/banner-setting.component';
import { KycActionPageComponent } from './kyc-action-page/kyc-action-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FiatManagementComponent } from './fiat-management/fiat-management.component';
import { HotcoldwalletManagementComponent } from './hotcoldwallet-management/hotcoldwallet-management.component';
import { ViewtransactionComponent } from './viewtransaction/viewtransaction.component';
import { TradeManagementComponent } from './trade-management/trade-management.component';
import { TradeDetailsComponent } from './trade-details/trade-details.component';
import { CreateSubadminComponent } from './create-subadmin/create-subadmin.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { EnquireManagementComponent } from './enquire-management/enquire-management.component';
import { WalletdetailsComponent } from './walletdetails/walletdetails.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { AddNewStaffComponent } from './add-new-staff/add-new-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ViewStaffComponent } from './view-staff/view-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { ViewAdminComponent } from './view-admin/view-admin.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TakerMakerFeeComponent } from './taker-maker-fee/taker-maker-fee.component';
import { BankManagementComponent } from './bank-management/bank-management.component';
import { LogsManagementComponent } from './logs-management/logs-management.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketReplyComponent } from './ticket-reply/ticket-reply.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FeedbackListComponent } from './feedback-management/feedback-list/feedback-list.component';
import { FeedbackViewComponent } from './feedback-management/feedback-view/feedback-view.component';
import { FaqComponent } from './faq-management/faq/faq.component';
import { EditFaqComponent } from './faq-management/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './faq-management/view-faq/view-faq.component';
import { AddFaqComponent } from './faq-management/add-faq/add-faq.component';
import { ListBlogComponent } from './blog-management/list-blog/list-blog.component';
import { AddBlogComponent } from './blog-management/add-blog/add-blog.component';
import { ViewBlogComponent } from './blog-management/view-blog/view-blog.component';
import { EditBlogComponent } from './blog-management/edit-blog/edit-blog.component';
import { StaticContentAddComponent } from './statics-content/static-content-add/static-content-add.component';
import { ListAnnouncementComponent } from './announcement-management/list-announcement/list-announcement.component';
import { AddAnnouncementComponent } from './announcement-management/add-announcement/add-announcement.component';
import { EditAnnouncementComponent } from './announcement-management/edit-announcement/edit-announcement.component';
import { ViewAnnouncementComponent } from './announcement-management/view-announcement/view-announcement.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ContactusManagementComponent } from './contactus-management/contactus-management.component';
import { CommunityAddComponent } from './community-management/community-add/community-add.component';
import { CommunityEditComponent } from './community-management/community-edit/community-edit.component';
import { CommunityListComponent } from './community-management/community-list/community-list.component';
import { CarrerCategoryListComponent } from './career-management/carrer-category-list/carrer-category-list.component';
import { CarrerCategoryAddComponent } from './career-management/carrer-category-add/carrer-category-add.component';
import { CareerCategoryEditComponent } from './career-management/career-category-edit/career-category-edit.component';
import { CareerCategoryViewComponent } from './career-management/career-category-view/career-category-view.component';
import { ListingUserListComponent } from './listing-managment/listing-user-list/listing-user-list.component';
import { CareerSubcategoryListComponent } from './career-subcategory-list/career-subcategory-list.component';
import { CareerSubcategoryAddComponent } from './career-subcategory-add/career-subcategory-add.component';
import { CareerSubcategoryViewComponent } from './career-subcategory-view/career-subcategory-view.component';
import { CareerSubcategoryEditComponent } from './career-subcategory-edit/career-subcategory-edit.component';
import { ListingUserViewComponent } from './listing-managment/listing-user-view/listing-user-view.component';
import { UserDeatilsNomineeComponent } from './user-deatils-nominee/user-deatils-nominee.component';
import { ManageFeeComponent } from './fee-management/manage-fee/manage-fee.component';
import { ContactusViewComponent } from './contactus-management/contactus-view/contactus-view.component';
import { FdListComponent } from './fixed-deposit-management/fd-list/fd-list.component';
import { FdViewComponent } from './fixed-deposit-management/fd-view/fd-view.component';
import { ListBannerComponent } from './banner-management/list-banner/list-banner.component';
import { FixedPercentageComponent } from './fixed-deposit-management/fixed-percentage/fixed-percentage.component';

import { ListingCoinComponent } from './listing-coin/listing-coin.component';
import { ManageCoinListComponent } from './manage-coin-list/manage-coin-list.component';
import { AddTeamComponent } from './team-management/add-team/add-team.component';
import { EditTeamComponent } from './team-management/edit-team/edit-team.component';
import { ViewTeamComponent } from './team-management/view-team/view-team.component';
import { ListTeamComponent } from './team-management/list-team/list-team.component';
import { FeeManageComponent } from './fee-management/fee-manage/fee-manage.component';
import { InstantSwapManagementComponent } from './instant-swap-management/instant-swap-management.component';
import { AddAipPlanComponent } from './aip-management/add-aip-plan/add-aip-plan.component';
import { ListAipPlanComponent } from './aip-management/list-aip-plan/list-aip-plan.component';
import { ListAipDetailsComponent } from './aip-management/list-aip-details/list-aip-details.component';
import { ViewAipDetailComponent } from './aip-management/view-aip-detail/view-aip-detail.component';
import { EditAipDetailComponent } from './aip-management/edit-aip-detail/edit-aip-detail.component';
import { SettlementManagementComponent } from './settlement-management/settlement-management.component';
import { ViewSettlementComponent } from './settlement-management/view-settlement/view-settlement.component';
import { FundsListComponent } from './funds-management/funds-list/funds-list.component';
import { ViewDepositComponent } from './funds-management/view-deposit/view-deposit.component';
import { ViewFundWithdrawComponent } from './funds-management/view-fund-withdraw/view-fund-withdraw.component';
import { AdminBankComponent } from './bank-management/admin-bank/admin-bank.component';
import { AdminBankListComponent } from './bank-management/admin-bank-list/admin-bank-list.component';
import { AdminBankViewComponent } from './bank-management/admin-bank-view/admin-bank-view.component';
import { FeeManageNewComponent } from './fee-manage-new/fee-manage-new.component';
import { SwapPercentageComponent } from './instant-swap-management/swap-percentage/swap-percentage.component';
import { CommisiionManagementListComponent } from './commission-management/commisiion-management-list/commisiion-management-list.component';
import { CommissionTransactionHistoryComponent } from './commission-management/commission-transaction-history/commission-transaction-history.component';
import { TrxManagementComponent } from './trx-management/trx-management.component';
import { ReferalAddComponent } from './referal-management/referal-add/referal-add.component';
import { ReferalListComponent } from './referal-management/referal-list/referal-list.component';
import { CoinDescriptionComponent } from './coin-description/coin-description.component';
import { CoinDescriptionListComponent } from './coin-description-management/coin-description-list/coin-description-list.component';
import { TdsManagementComponent } from './tds-management/tds-management.component';
import { TdsViewComponent } from './tds-view/tds-view.component';

import { ListAcademyComponent } from './academy-management/list-academy/list-academy.component';
import { AddAcademyComponent } from './academy-management/add-academy/add-academy.component';
import { EditAcademyComponent } from './academy-management/edit-academy/edit-academy.component';
import { ViewAcademyComponent } from './academy-management/view-academy/view-academy.component';
import { ListAcademyCategoryComponent } from './academy-category-management/list-academy-category/list-academy-category.component';
import { AddAcademyCategoryComponent } from './academy-category-management/add-academy-category/add-academy-category.component';
import { EditAcademyCategoryComponent } from './academy-category-management/edit-academy-category/edit-academy-category.component';
import { ViewAcademyCategoryComponent } from './academy-category-management/view-academy-category/view-academy-category.component';

import { AddAnnouncementCategoryComponent } from './announcement-management/category-announcement-management/add-announcement-category/add-announcement-category.component';
import { EditAnnouncementCategoryComponent } from './announcement-management/category-announcement-management/edit-announcement-category/edit-announcement-category.component';
import { ViewAnnouncementCategoryComponent } from './announcement-management/category-announcement-management/view-announcement-category/view-announcement-category.component';
import { ListAnnouncementCategoryComponent } from './announcement-management/category-announcement-management/list-announcement-category/list-announcement-category.component';

import { NewsListComponent } from './newsManagement/news-list/news-list.component';
import { NewsCategoryComponent } from './newsManagement/news-category/news-category.component';
import { NewsAddComponent } from './newsManagement/news-add/news-add.component';
import { NewsEditComponent } from './newsManagement/news-edit/news-edit.component';
import { NewsViewComponent } from './newsManagement/news-view/news-view.component';
import { NewsCategoryListComponent } from './newsManagement/newsCategorty/news-category-list/news-category-list.component';
import { NewsCategoryAddComponent } from './newsManagement/newsCategorty/news-category-add/news-category-add.component';
import { NewsCategoryEditComponent } from './newsManagement/newsCategorty/news-category-edit/news-category-edit.component';
import { FaqCategoryComponent } from './pages/static-content-management/faq-category/faq-category.component';
import { FaqCategoryAddComponent } from './pages/static-content-management/faq-category-add/faq-category-add.component';
import { FaqCategoryEditComponent } from './pages/static-content-management/faq-category-edit/faq-category-edit.component';
import { BlogCategoryListComponent } from './blog-management/blog-category/blog-category-list/blog-category-list.component';
import { BlogCategoryAddComponent } from './blog-management/blog-category/blog-category-add/blog-category-add.component';
import { BlogCategoryEditComponent } from './blog-management/blog-category/blog-category-edit/blog-category-edit.component';
import { AddExecBaseCoinComponent } from './instant-swap-management/add-exec-base-coin/add-exec-base-coin.component';
import { ListExecBaseCoinComponent } from './instant-swap-management/list-exec-base-coin/list-exec-base-coin.component';
import { FaqCategoryViewComponent } from './pages/static-content-management/faq-category-view/faq-category-view.component';
import { RiskPolicyComponent } from './pages/static-content-management/risk-policy/risk-policy.component';
import { CookiePolicyComponent } from './pages/static-content-management/cookie-policy/cookie-policy.component';
import { RefundPolicyComponent } from './pages/static-content-management/refund-policy/refund-policy.component';
import { AmlAndKycPolicyComponent } from './pages/static-content-management/aml-and-kyc-policy/aml-and-kyc-policy.component';
import { InstantAddBuySellComponent } from './instant-add-buy-sell/instant-add-buy-sell.component';
import { InstantBuySellListComponent } from './instant-buy-sell-list/instant-buy-sell-list.component';
import { NewlyAddedCoinListComponent } from './newly-added-coin-list/newly-added-coin-list.component';
import { InstantBuySellManagementHitoryComponent } from './instant-buy-sell-management-hitory/instant-buy-sell-management-hitory.component';
import { MarkupSettingComponent } from './markup-setting/markup-setting.component';
import { ReferralHistoryComponent } from './referral-history/referral-history.component';
import { EditNewlyAddedCoinComponent } from './edit-newly-added-coin/edit-newly-added-coin.component';
import { OrderBookListComponent } from './pages/order-book-list/order-book-list.component';
import { OrderBookUpdateComponent } from './pages/order-book-update/order-book-update.component';
import { TradingHistoryComponent } from './trading-history/trading-history.component';
import { ReferalFdPercentageComponent } from './referal-management/referal-fd-percentage/referal-fd-percentage.component';
import { ViewFundUpiComponent } from './funds-management/view-fund-upi/view-fund-upi.component';
import { ViewBankDetailUserComponent } from './bank-management/view-bank-detail-user/view-bank-detail-user.component';
import { EditExecBaseCoinComponent } from './instant-swap-management/edit-exec-base-coin/edit-exec-base-coin.component';
import { DepositDescriptionComponent } from './statics-content/deposit-description/deposit-description.component';
import { WithdrawalDescriptionComponent } from './statics-content/withdrawal-description/withdrawal-description.component';
import { TransferDescriptionComponent } from './statics-content/transfer-description/transfer-description.component';

const routes: Routes = [
  { path: '', redirectTo: "/login", pathMatch: "full" },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'user-management', component: UserManagementComponent },
  { path: 'market-management/:action', component: MarketManagementComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'sub-admin-management', component: SubAdminManagementComponent },
  { path: 'wallet-management', component: WalletManagementComponent },
  { path: 'statics-content', component: StaticsContentComponent },
  { path: 'about-us/:id', component: AboutUsComponent },
  { path: 'term-and-service/:id', component: TermAndServiceComponent },
  { path: 'privacy/:id', component: PrivacyComponent },
  { path: 'disclaimer/:id', component: DisclaimerComponent },
  { path: 'exchange-management', component: ExchangeManagementComponent },
  { path: 'payment-and-transaction', component: PaymentAndTransactionComponent },
  { path: 'fund-view', component: ViewFundWithdrawComponent, },
  { path: 'kyc-management', component: KycManagementComponent, },
  { path: 'list-fund', component: FundsListComponent, },
  { path: 'view-deposit', component: ViewDepositComponent },
  { path: 'ticket-management', component: TicketManagementComponent },
  { path: 'ticket-details/:id', component: TicketDetailsComponent },
  { path: 'ticket-reply/:id', component: TicketReplyComponent },
  { path: 'add-wallet-address', component: AddWalletAddressComponent },
  { path: 'withdrawl-fee', component: WithdrawlFeeComponent },
  { path: 'daily-limit', component: DailyLimitComponent },
  { path: 'withdrawl-limit', component: WithdrawlLimitComponent },
  { path: 'previlage-setting', component: PrevilageSettingComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'contactUs', component: ContactusManagementComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'banner-setting', component: BannerSettingComponent },
  { path: 'kyc-action/:id', component: KycActionPageComponent },
  { path: 'fiat-management', component: FiatManagementComponent },
  { path: 'hotcoldwallet-management', component: HotcoldwalletManagementComponent },
  { path: 'viewtransaction/:id', component: ViewtransactionComponent },
  { path: 'user-details', component: UserDetailsComponent },
  { path: 'trade-management', component: TradeManagementComponent },
  { path: 'trade-details/:id', component: TradeDetailsComponent },
  { path: 'create-subadmin', component: CreateSubadminComponent },
  { path: 'profit-loss', component: ProfitLossComponent },
  { path: 'enquiry-management', component: EnquireManagementComponent },
  { path: 'walletdetails/:id', component: WalletdetailsComponent },
  { path: 'staff-management', component: StaffManagementComponent },
  { path: 'add-new-staff', component: AddNewStaffComponent },
  { path: 'edit-staff', component: EditStaffComponent },
  { path: 'view-staff', component: ViewStaffComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'admin-management', component: AdminManagementComponent },
  { path: 'add-admin', component: AddAdminComponent },
  { path: 'edit-admin', component: EditAdminComponent },
  { path: 'view-admin/:id', component: ViewAdminComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'taker-maker-fee', component: TakerMakerFeeComponent },
  { path: 'category-list', component: CarrerCategoryListComponent },
  { path: 'category-add', component: CarrerCategoryAddComponent },
  { path: 'category-edit', component: CareerCategoryEditComponent },
  { path: 'category-view', component: CareerCategoryViewComponent },
  { path: 'listing-user', component: ListingUserListComponent },
  { path: 'listing-view', component: ListingUserViewComponent },
  { path: 'faq-management', component: FaqComponent },
  { path: 'add-faq', component: AddFaqComponent },
  { path: 'edit-faq', component: EditFaqComponent },
  { path: 'view-faq', component: ViewFaqComponent },
  { path: 'list-blog', component: ListBlogComponent },
  { path: 'add-blog', component: AddBlogComponent },
  { path: 'view-blog', component: ViewBlogComponent },
  { path: 'edit-blog', component: EditBlogComponent },
  { path: 'add-static', component: StaticContentAddComponent },
  { path: 'list-announcement', component: ListAnnouncementComponent },
  { path: 'add-announcement', component: AddAnnouncementComponent },
  { path: 'edit-announcement', component: EditAnnouncementComponent },
  { path: 'view-announcement', component: ViewAnnouncementComponent },
  { path: 'community-list', component: CommunityListComponent },
  { path: 'community-add', component: CommunityAddComponent },
  { path: 'community-edit', component: CommunityEditComponent },
  { path: 'partner/:id', component: PartnershipComponent },
  { path: 'logs-management', component: LogsManagementComponent },
  { path: 'bank-management', component: BankManagementComponent },
  { path: 'admin-bank-details', component: AdminBankComponent },
  { path: 'admin-bank-list', component: AdminBankListComponent },
  { path: 'bank-view', component: AdminBankViewComponent },
  { path: 'feedback-list', component: FeedbackListComponent },
  { path: 'feedback-view', component: FeedbackViewComponent },
  { path: 'career-subcat-list', component: CareerSubcategoryListComponent },
  { path: 'career-subcat-edit', component: CareerSubcategoryEditComponent },
  { path: 'career-subcat-add', component: CareerSubcategoryAddComponent },
  { path: 'career-subcat-view', component: CareerSubcategoryViewComponent },
  { path: 'nominee-view', component: UserDeatilsNomineeComponent },
  { path: 'contactus-view', component: ContactusViewComponent },
  { path: 'fd-list', component: FdListComponent },
  { path: 'fd-view', component: FdViewComponent },
  { path: 'list-banner', component: ListBannerComponent },
  { path: 'add-banner', component: AddBannerComponent },
  { path: 'view-banner', component: ViewBannerComponent },
  { path: 'edit-banner', component: EditBannerComponent },
  { path: 'fd-percentage', component: FixedPercentageComponent },
  { path: 'listing-coin', component: ListingCoinComponent },
  { path: 'manage-coin', component: ManageCoinListComponent },
  { path: 'add-team', component: AddTeamComponent },
  { path: 'edit-team', component: EditTeamComponent },
  { path: 'view-team', component: ViewTeamComponent },
  { path: 'list-team', component: ListTeamComponent },
  { path: 'fee-management', component: FeeManageComponent },
  { path: 'instant-list', component: InstantSwapManagementComponent },
  { path: 'swap-percentage', component: SwapPercentageComponent },

  { path: 'add-aip', component: AddAipPlanComponent },
  { path: 'list-aip', component: ListAipPlanComponent },
  { path: 'list-aip-percent', component: ListAipPercentageComponent },
  { path: 'add-aip-percent', component: AddAipPercentComponent },
  { path: 'edit-aip-percent', component: EditAipPercentComponent },
  { path: 'list-aip-details', component: ListAipDetailsComponent },
  { path: 'view-aip-details', component: ViewAipDetailComponent },
  { path: 'edit-aip-details', component: EditAipDetailComponent },
  { path: 'settlement-management', component: SettlementManagementComponent },
  { path: 'view-settlement', component: ViewSettlementComponent },
  { path: 'fee-manage-new', component: FeeManageNewComponent },
  { path: 'commission-management', component: CommisiionManagementListComponent },
  { path: 'commission-transaction-history', component: CommissionTransactionHistoryComponent },
  { path: 'trx-manages', component: TrxManagementComponent },
  { path: 'referal-add', component: ReferalAddComponent },
  { path: 'referal-list', component: ReferalListComponent },

  { path: 'coin-description', component: CoinDescriptionComponent },
  { path: 'coin-description-list', component: CoinDescriptionListComponent },
  { path: 'tds', component: TdsManagementComponent },
  { path: 'tds-view', component: TdsViewComponent },

  // academy management
  { path: 'list-academy', component: ListAcademyComponent },
  { path: 'add-academy', component: AddAcademyComponent },
  { path: 'edit-academy', component: EditAcademyComponent },
  { path: 'view-academy', component: ViewAcademyComponent },

  { path: 'list-academy-category', component: ListAcademyCategoryComponent },
  { path: 'add-academy-category', component: AddAcademyCategoryComponent },
  { path: 'edit-academy-category', component: EditAcademyCategoryComponent },
  { path: 'view-academy-category', component: ViewAcademyCategoryComponent },

  { path: 'add-announcement-category', component: AddAnnouncementCategoryComponent },
  { path: 'edit-announcement-category', component: EditAnnouncementCategoryComponent },
  { path: 'view-announcement-category', component: ViewAnnouncementCategoryComponent },
  { path: 'list-announcement-category', component: ListAnnouncementCategoryComponent },

  { path: 'news-list', component: NewsListComponent },
  { path: 'news-category', component: NewsCategoryComponent },
  { path: 'news-add', component: NewsAddComponent },
  { path: 'news-edit', component: NewsEditComponent },
  { path: 'news-view', component: NewsViewComponent },
  { path: 'news-category-list', component: NewsCategoryListComponent },
  { path: 'news-category-add', component: NewsCategoryAddComponent },
  { path: 'news-category-edit', component: NewsCategoryEditComponent },
  { path: 'faq-category', component: FaqCategoryComponent },
  { path: 'faq-category-add', component: FaqCategoryAddComponent },
  { path: 'faq-category-edit', component: FaqCategoryEditComponent },
  { path: 'blog-category-list', component: BlogCategoryListComponent },
  { path: 'blog-category-add', component: BlogCategoryAddComponent },
  { path: 'blog-category-edit', component: BlogCategoryEditComponent },

  { path: 'faq-category', component: FaqCategoryComponent },
  { path: 'faq-category-add', component: FaqCategoryAddComponent },
  { path: 'faq-category-edit', component: FaqCategoryEditComponent },
  { path: 'faq-category-view', component: FaqCategoryViewComponent },

  { path: 'blog-category-list', component: BlogCategoryListComponent },
  { path: 'blog-category-add', component: BlogCategoryAddComponent },
  { path: 'blog-category-edit', component: BlogCategoryEditComponent },
  { path: 'add-swap-coin', component: AddExecBaseCoinComponent },
  { path: 'edit-swap-coin', component: EditExecBaseCoinComponent },
  { path: 'list-swap-coin', component: ListExecBaseCoinComponent },
  { path: 'risk-policy', component: RiskPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'aml-kyc-policy', component: AmlAndKycPolicyComponent },
  { path: 'instant-add-buy-sell', component: InstantAddBuySellComponent },
  { path: 'instant-buy-sell-list', component: InstantBuySellListComponent },
  { path: 'newly-added-coin-list', component: NewlyAddedCoinListComponent },
  { path: 'instant-buy-sell-history', component: InstantBuySellManagementHitoryComponent },
  { path: 'markup-setting', component: MarkupSettingComponent },
  { path: 'referral-history', component: ReferralHistoryComponent },
  { path: 'edit-newly-added-coin', component: EditNewlyAddedCoinComponent },
  { path: 'order-book-list', component: OrderBookListComponent },
  { path: 'order-book-update', component: OrderBookUpdateComponent },
  { path: 'trading-history', component: TradingHistoryComponent },
  { path: 'referal-fd-percentage', component: ReferalFdPercentageComponent },
  { path: 'upi-view', component: ViewFundUpiComponent },
  { path: 'view-bank-detail-user', component: ViewBankDetailUserComponent },
  { path: 'deposit-description', component: DepositDescriptionComponent },
  { path: 'withdrawal-description', component: WithdrawalDescriptionComponent },
  { path: 'transfer-description', component: TransferDescriptionComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
