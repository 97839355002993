import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;

  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
  }

  myProfile() {
    this.editForm = new FormGroup({
      'name': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'city': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'country': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl(''),
      'phone':new FormControl(''),
      'address': new FormControl('',Validators.required)
    })
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.editImage = this.userDetail.imageUrl;
        this.editForm.patchValue({
          'name': this.userDetail.firstName,
          'email': this.userDetail.email,
          'phone': this.userDetail.phoneNo,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  updateProfile() {
   
    var apiReq = {
      "address": this.editForm.value.address,
      "city": this.editForm.value.city,
      "country": this.editForm.value.country,
      "firstName": this.editForm.value.name,
      "phoneNo": this.editForm.value.phone,
      "gender": this.editForm.value.gender,
      "email": this.editForm.value.email,
      "imageUrl": this.editImage ? this.editImage : this.userDetail.imageUrl
    }
    this.service.showSpinner();
    this.service.post('account/profile-update', apiReq).subscribe(res => {
      if (res['status'] == 200) {
        this.service.changeLoginSub('login')
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }

}
