import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { IMyDpOptions } from "mydatepicker";

@Component({
  selector: "app-add-new-staff",
  templateUrl: "./add-new-staff.component.html",
  styleUrls: ["./add-new-staff.component.css"],
})
export class AddNewStaffComponent implements OnInit {
  addForm: FormGroup;
  ipAddress: any;
  newArr: any = [];
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  CountryCodeList: any;
  products: any = [];
  editImage: any;
  imageData: any = "assets/img/profile-img.jpg";
  type_pass: any = "password";
  type_pass2: any = "password";

  privilage = [
    {
      name: 'Dashboard',
      key: 'DASHBOARD'
    },
    {
      name: 'User Management',
      key: 'USER_MANAGEMENT'
    },
    {
      name: 'Sub Admin Management',
      key: 'STAFF_MANAGEMENT'
    },
    {
      name: 'Wallet Management',
      key: 'WALLET_MANAGEMENT'
    },
    {
      name: 'Hot Wallet Management',
      key: 'HOT_COLD_LIMIT_MANAGEMENT'
    },
    {
      name: 'KYC Management',
      key: 'KYC_MANAGEMENT'
    },
    {
      name: 'FD Management',
      key: 'FD_MANAGEMENT'
    },
    {
      name: 'Instant Swap Management',
      key: 'INSTANT_SWAP_MANAGEMENT'
    },
    {
      name: 'AIP Management',
      key: 'AIP_MANAGEMENT'
    },
    {
      name: 'Fee Management',
      key: 'FEE_MANAGEMENT'
    },
    {
      name: 'Fund Management',
      key: 'FUND_MANAGEMENT'
    },
    // {
    //   name: 'TRX Management',
    //   key: 'TRX_MANAGEMENT'
    // },
    {
      name: 'Settlement Management',
      key: 'SETTLEMENT_MANAGEMENT'
    },
    {
      name: 'Referal Management',
      key: 'REFERRAL_MANAGEMENT'
    },
    {
      name: 'Commission Management',
      key: 'COMMISSION_MANAGEMENT'
    },
    {
      name: 'Ticket Management',
      key: 'TICKET_MANAGEMENT'
    },
    {
      name: 'Bank Management',
      key: 'BANK_MANAGEMENT'
    },
    {
      name: 'TDS Management',
      key: 'TDS_MANAGEMENT'
    },
    {
      name: 'Static Content Management',
      key: 'STATIC_CONTENT'
    },
    {
      name: 'Academy Management',
      key: 'ACADEMY_MANAGEMENT'
    },
    {
      name: 'Faq Management',
      key: 'FAQ_MANAGEMENT'
    },
    {
      name: 'Logs Management',
      key: 'LOGS_MANAGEMENT'
    },
    {
      name: 'Teams Management',
      key: 'TEAMS_MANAGEMENT'
    },
    {
      name: 'News Management',
      key: 'NEWS_MANAGEMENT'
    },
    {
      name: 'Setting Management',
      key: 'SETTINGS'
    },
    {
      name: 'Trading History',
      key: 'TRADE_HISTORY'
    }
  ]
  constructor(
    public service: ServiceService,
    public router: Router,
    private http: HttpClient
  ) {
    this.http.get<{ ip: string }>("https://jsonip.com").subscribe((data) => {
      this.ipAddress = data;
    });
  }

  ngOnInit() {
    this.addForm = new FormGroup({
      firstName: new FormControl("", [
        Validators.pattern(/^[a-z,.'-]+$/i),
        Validators.required,
      ]),
      lastName: new FormControl("", [
        Validators.pattern(/^[a-z,.'-]+$/i),
        Validators.required,
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      gender: new FormControl("", Validators.required),
      role: new FormControl("", Validators.required),
      phoneNumber: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[1-9][0-9]{8,13}$/),
        Validators.maxLength(18),
      ]),
      dob: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      selectState: new FormControl("", Validators.required),
      selectCity: new FormControl("", [Validators.required]),
      zipCode: new FormControl("", [Validators.required]),
      address: new FormControl("", [
        Validators.maxLength(256),
        Validators.required,
      ]),
      countryCode: new FormControl("+91", [Validators.required]),
    });

    this.onDateChanged();
    this.countryList = this.service.countryListJson;
    this.http.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  }

  getStateList(event) {
    var stateList = this.countryList.filter(
      (x) => x.country == event.target.value
    );
    this.stateList = stateList[0].states;
    this.countryCode = stateList[0].code;
    this.addForm.patchValue({
      'countryCode': this.countryCode
    })

  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
    height: "33px",
  };
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear() - 18,
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.myDatePickerOptions = copy1;
  }
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  addStaff() {
    let data = {
      address: this.addForm.value.address,
      city: this.addForm.value.selectCity,
      country: this.addForm.value.selectCountry,
      countryCode: this.addForm.value.countryCode,
      dob: this.addForm.value.dob.formatted,
      email: this.addForm.value.email,
      firstName: this.addForm.value.firstName,
      gender: this.addForm.value.gender,
      imageUrl: this.editImage,
      ipAddress: this.ipAddress.ip,
      lastName: this.addForm.value.lastName,
      location: "string",
      phoneNo: this.addForm.value.phoneNumber,
      previlage: this.newArr,
      zipCode: this.addForm.value.zipCode,
      roleStatus: this.addForm.value.role,
      state: this.addForm.value.selectState,
      webUrl: this.service.websiteURL + "reset-password",
      userIdToUpdate: '',
    };
    this.service.showSpinner();
    this.service
      .postApi("account/admin/user-management/create-sub-admin", data)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res.status == 200) {
            this.service.toasterSucc(res.message);
            this.router.navigate(["/staff-management"]);
          } else if (res.status == 205) this.service.toasterSucc(res.message);
        },
        (err) => {
          this.service.hideSpinner();
          if (err.status == 400) {
            this.service.toasterErr(err.error.message);
          } else {
            this.service.toasterErr(err.error.message);
          }
        }
      );
  }
  switchEye() {
    this.type_pass = "text";
  }

  switchToText() {
    this.type_pass = "password";
  }

  switchEye2() {
    this.type_pass2 = "text";
  }

  switchToText2() {
    this.type_pass2 = "password";
  }
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex((x) => {
        x == value;
      });
      this.newArr.splice(index, 1);
    }
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append("file", img);
    this.service.showSpinner();
    this.service.postApi("account/upload-file", fb).subscribe(
      (res) => {
        if (res["status"] == "200") {
          this.editImage = res["data"];
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
}
